import { defineMessages } from "react-intl";
import {
  ABOUT_WE_ARE_SPRADLING,
  ABOUT_SPRADLING_IN_THE_WORLD,
  ABOUT_WHY_CHOOSE_US,
  // WARNING: unused ABOUT_WORK_WITH_US route
  // ABOUT_WORK_WITH_US,
  ABOUT_BRANDS,
} from "routes";
import { getTranslatedLink } from "services/instances";

const WE_ARE_SPRADLING = (intl) => ({
  text: intl.formatMessage(
    defineMessages({
      weAreSpradling: {
        id: "HeaderMainMenu.weAreSpradling",
        description:
          "Somos Spradling link text in about spradling header dropdown",
        defaultMessage: "Somos Spradling",
      },
    }).weAreSpradling
  ),
  to: getTranslatedLink(ABOUT_WE_ARE_SPRADLING, {}),
});

const WHY_CHOOSE_US = (intl) => ({
  text: intl.formatMessage(
    defineMessages({
      whyUs: {
        id: "HeaderMainMenu.whyUs",
        description:
          "¿Por qué escogernos? link text in about spradling header dropdown",
        defaultMessage: "¿Por qué escogernos?",
      },
    }).whyUs
  ),
  to: getTranslatedLink(ABOUT_WHY_CHOOSE_US, {}),
});

const BRANDS = (intl) => ({
  text: intl.formatMessage(
    defineMessages({
      brands: {
        id: "HeaderMainMenu.brands",
        description: "marcas link text in about spradling header dropdown",
        defaultMessage: "Marcas",
      },
    }).brands
  ),
  to: getTranslatedLink(ABOUT_BRANDS, {}),
});

const SPRADLING_IN_THE_WORLD = (intl) => ({
  text: intl.formatMessage(
    defineMessages({
      spradlingGlobally: {
        id: "HeaderMainMenu.spradlingGlobally",
        description:
          "Spradling en el mundo link text in about spradling header dropdown",
        defaultMessage: "Spradling en el mundo",
      },
    }).spradlingGlobally
  ),
  to: getTranslatedLink(ABOUT_SPRADLING_IN_THE_WORLD, {}),
});

const GLOBAL_ABOUT_LINKS = (intl) => [
  WE_ARE_SPRADLING(intl),
  WHY_CHOOSE_US(intl),
  BRANDS(intl),
  SPRADLING_IN_THE_WORLD(intl),
];

const LATAM_MX_ABOUT_LINKS = (intl) => [
  WE_ARE_SPRADLING(intl),
  WHY_CHOOSE_US(intl),
  BRANDS(intl),
  SPRADLING_IN_THE_WORLD(intl),
];

const RESOURCES_ABOUT_LINKS = (intl) => [
  WE_ARE_SPRADLING(intl),
  WHY_CHOOSE_US(intl),
  SPRADLING_IN_THE_WORLD(intl),
];

export const ABOUT_LINKS = ({ intl }) => ({
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: LATAM_MX_ABOUT_LINKS(intl),
  [process.env.REACT_APP_MX_INSTANCE_ID]: LATAM_MX_ABOUT_LINKS(intl),
  [process.env.REACT_APP_USA_INSTANCE_ID]: GLOBAL_ABOUT_LINKS(intl),
  [process.env.REACT_APP_SM_INSTANCE_ID]: GLOBAL_ABOUT_LINKS(intl),
  [process.env.REACT_APP_RC_INSTANCE_ID]: RESOURCES_ABOUT_LINKS(intl),
  [process.env.REACT_APP_EU_INSTANCE_ID]: GLOBAL_ABOUT_LINKS(intl),
});

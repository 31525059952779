import PropTypes from "prop-types";
import classNames from "classnames/bind";
import {Link} from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import {isMARINE} from "services/instances";
import "./styles/HeaderDropdown.scss";

const NO_OP = () => {}
function HeaderDropdown({isOpen, links, closeMobileMenu, onLinkClick = NO_OP}) {

  // Handles click on the link to close the mobile menu and execute given onLinkClick function
  function handleLinkClick() {
    closeMobileMenu();
    onLinkClick();
  }

  HeaderDropdown.handleClickOutside = () => {
    if (window.innerWidth >= 992)  // bootstrap's lg-up
      onLinkClick();
  }

  if (!isOpen)
    return null;

  return (
    <ul className={classNames({
      "header-dropdown": true,
      "header-dropdown--open": isOpen
    })}>
      {
        links.map((link, index) =>
          <li
            key={index}
            className={classNames({
              "header-dropdown__link": true,
              "header-dropdown__link--marine": isMARINE()
            })}
            onClick={handleLinkClick}>
            <Link to={link.to}>
              {link.text}
            </Link>
          </li>
        )
      }
    </ul>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => HeaderDropdown.handleClickOutside
};

HeaderDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        to: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            hash: PropTypes.string,
            state: PropTypes.object
          })
        ])
      })
  ).isRequired,
  closeMobileMenu: PropTypes.func,
  onLinkClick: PropTypes.func
};

export default onClickOutside(HeaderDropdown, clickOutsideConfig);
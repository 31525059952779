import gql from "graphql-tag";

const categoryQuery = gql`
  query CategoryQuery($slug: String) {
    blogCategory(slug: $slug) {
      id
      name
      description
      coverUrl
      altCover
      slug
    }
  }
`;

export default categoryQuery;

import gql from "graphql-tag";
import pageInfoFragment from "utils/fragments/PageInfoFragment";

const relatedPostsQuery = gql`
  query RelatedPostsQuery(
    $postId: String
    $first: Int
    $last: Int
    $afterCursor: String
    $beforeCursor: String
  ) {
    posts(
      relatedWith: $postId
      first: $first
      last: $last
      after: $afterCursor
      before: $beforeCursor
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          title
          slug
          listingImageThumbSmall
          instances {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${pageInfoFragment}
`;

export default relatedPostsQuery;

import { useQuery } from "react-apollo";
import { useLocation } from "react-router-dom";
import QueryParams from "services/browser-history/QueryParams";
import { getCurrentInstanceId } from "services/instances";
import { FETCH_POLICY_CACHE_AND_NETWORK } from "apollo/constants";
// svdsvcd
import Hero from "./components/Hero";
import { FormattedMessage } from "react-intl";
import heroBackground from "./components/Hero/img/hero-background-v2.jpg";
import { Element } from "react-scroll";
import Paginator from "utils/components/Paginator";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import LoadingMessage from "utils/components/LoadingMessage";
import CategoryCard from "./components/CategoryCard";
import postsQuery from "./query";

const PAGE_SIZE = 6;

const QUERY_VARIABLES = (queryParams) => ({
  ...queryParams.paginationGraphQLVariables(PAGE_SIZE),
  instances: [getCurrentInstanceId()],
  // categories: [categorySlug],
  categories: [],
});

const Categories = () => {
  const location = useLocation();
  const queryParams = new QueryParams(location.search);
  const { loading, data, error } = useQuery(postsQuery, {
    variables: QUERY_VARIABLES(queryParams),
    fetchPolicy: FETCH_POLICY_CACHE_AND_NETWORK,
  });

  return (
    <main className="projects-list-page">
      <ScrollTopOnMount />
      <Hero
        title={
          <FormattedMessage
            id="PostsListPage.title"
            defaultMessage="Noticias"
          />
        }
        description={
          <FormattedMessage
            id="ProjectsListPage.test"
            description="Content of the projects list page sub hero"
            defaultMessage="Test label Test label Test label Test label Test label Test label"
          />
        }
        backgroundImage={heroBackground}
      />
      <Element name="blogCategoriesList">
        <div className="container">
          {error && <LoadingMessage />}
          {loading && (
            <div className="row">
              {[1, 2, 3].map((number) => (
                <div className="col-12 col-md-6 col-lg-4 mb-4" key={number}>
                  <CategoryCard />
                </div>
              ))}
            </div>
          )}
          {data?.posts && (
            <>
              <div className="row">
                {data.posts.edges.map(({ node: post }) => (
                  <div className="col-12 col-md-6 col-lg-4 mb-4" key={post.id}>
                    <CategoryCard post={post} />
                  </div>
                ))}
              </div>
              <Paginator
                elementId={"blogCategoriesList"}
                pageSize={PAGE_SIZE}
                pageInfo={data?.posts.pageInfo}
                totalCount={data?.posts.totalCount}
              />
            </>
          )}
        </div>
      </Element>
      {/* <SEO scene={PROJECTS_SCENE} /> */}
    </main>
  );
};

export default Categories;

import PropTypes from "prop-types";
import singlePostQuery from "modules/blog/PostDetails/query";
import { Redirect } from "react-router";
import { BLOG_LIST } from "routes";
import { useQuery } from "@apollo/react-hooks";
import SocialShareNav from "utils/components/SocialShareNav";
import "./styles/PostDetails.scss";
import { Link } from "react-router-dom";
import RelatedPosts from "modules/blog/PostDetails/components/RelatedPosts";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import LoadingMessage from "utils/components/LoadingMessage";
import HTMLContent from "utils/components/HTMLContent";
import { getTranslatedLink } from "services/instances";
import Hero from "../home/components/Hero";
import heroBackground from "../home/components/Hero/img/hero-background-v2.jpg";

export default function PostDetails({ match }) {
  const { loading, data } = useQuery(singlePostQuery, {
    variables: {
      slug: match.params.postSlug,
    },
  });

  if (loading)
    return (
      <div className="container">
        <div className="post-details">
          <LoadingMessage />
        </div>
      </div>
    );

  if (data) {
    const { post } = data;

    return (
      <>
        <ScrollTopOnMount />
        <Hero
          titleClassName="post-title-hero"
          title={post.title}
          backgroundImage={heroBackground}
        />
        <div className="container">
          <div className="post-details pt-5 pb-5">
            <HTMLContent html={post.content} />
            <div className="post-details__share mt-5">
              <hr />
              <div className="row">
                <div className="col-12 col-md-7">
                  <p className="post-tags mb-0">
                    {post.tags.map((tag, index) => (
                      <Link
                        key={index}
                        className="post-tags__tag text-weight-bold gray"
                        to={getTranslatedLink(BLOG_LIST)}
                      >
                        #{tag}
                      </Link>
                    ))}
                  </p>
                </div>
                <div className="col-12 col-md-5">
                  <SocialShareNav title={!loading ? post.title : ""} />
                </div>
              </div>
            </div>
            <RelatedPosts postId={post.id} />
          </div>
        </div>
      </>
    );
  }
  return <Redirect to={getTranslatedLink(BLOG_LIST, {})} />;
}

PostDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      postSlug: PropTypes.string,
    }),
  }),
};

import { Redirect } from "react-router";
import PropTypes from "prop-types";
import { getTranslatedLink, isMARINE } from "services/instances";
import HeroTitle from "utils/components/HeroTitle";
import SubHeroContent from "utils/components/SubHeroContent";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import PostsList from "modules/blog/PostsList";
import { useQuery } from "react-apollo";
import categoryQuery from "./query";
import { BLOG_LIST } from "routes";
import HTMLContent from "utils/components/HTMLContent";
import Skeleton from "react-loading-skeleton";

const CategoryDetail = ({
  match: {
    params: { categorySlug },
  },
}) => {
  const { loading, data, error } = useQuery(categoryQuery, {
    variables: {
      slug: categorySlug,
    },
  });
  if (error) return <Redirect to={getTranslatedLink(BLOG_LIST, {})} />;

  return (
    <main className="projects-list-page">
      <ScrollTopOnMount />
      <HeroTitle
        title={!loading ? data?.blogCategory.name : <Skeleton count={1} />}
        backgroundImage={data?.blogCategory.coverUrl}
      />
      <SubHeroContent
        theme={!isMARINE() ? "green" : "prussian-blue"}
        className="mb-4"
      >
        <div className="container pt-4 pb-4">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <p className="text-center mb-0">
                {!loading ? (
                  <HTMLContent html={data?.blogCategory.description} />
                ) : (
                  <Skeleton count={3} />
                )}
              </p>
            </div>
          </div>
        </div>
      </SubHeroContent>
      <div className="posts-list-page container mt-5">
        <PostsList categorySlug={categorySlug} />
      </div>
    </main>
  );
};

CategoryDetail.propTypes = {
  match: PropTypes.object,
};

export default CategoryDetail;

import PropTypes from "prop-types";
import className from "classnames/bind";
import { useLocation } from "react-router-dom";
import "./styles/PostsList.scss";
import {
  BACKGROUND_IMAGE_CARD_TYPE,
  COMMON_CARD_TYPE,
  FEATURED_CARD_TYPE,
} from "modules/blog/PostsList/components/BlogPostCard/constants";
import BlogPostCard from "modules/blog/PostsList/components/BlogPostCard";
import { Query } from "react-apollo";
import postsQuery from "modules/blog/PostsList/query";
import { BLOG_DETAILS } from "routes";
import Paginator from "utils/components/Paginator";
import { Element } from "react-scroll";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import LoadingMessage from "utils/components/LoadingMessage";
import { getCurrentInstanceId, getTranslatedLink } from "services/instances";
import QueryParams from "services/browser-history/QueryParams";

export default function PostsList({ categorySlug = "", showPaginator = true }) {
  console.log("categorySlug: ", categorySlug);
  const location = useLocation();
  const queryParams = new QueryParams(location.search);
  const pageSize = 6;

  /**
   * Get layout type based on index value
   * @param index
   * @return {number}
   */
  const getLayoutType = (index) => {
    if (index === 0 || index === 7) return BACKGROUND_IMAGE_CARD_TYPE;
    else if (index === 5) return FEATURED_CARD_TYPE;
    else return COMMON_CARD_TYPE;
  };

  const queryVariables = {
    ...queryParams.paginationGraphQLVariables(pageSize),
    instances: [getCurrentInstanceId()],
    categories: [categorySlug],
  };

  return (
    <Element name="postsListElement">
      <ScrollTopOnMount />
      <Query query={postsQuery} variables={queryVariables}>
        {({ loading, data }) => {
          if (loading)
            return (
              <div className="row blog-posts-list">
                {[0, 1, 2, 3, 4, 5].map((item) => {
                  const post = {
                    layoutType: getLayoutType(item),
                  };
                  return (
                    <div
                      className={className({
                        "mb-3": true,
                        "col-xs-12 col-sm-12 col-md-12 col-lg-6":
                          post.layoutType === BACKGROUND_IMAGE_CARD_TYPE ||
                          post.layoutType === FEATURED_CARD_TYPE,
                        "col-xs-6 col-sm-6 col-md-6 col-lg-3":
                          post.layoutType === COMMON_CARD_TYPE,
                      })}
                      key={item}
                    >
                      <BlogPostCard loading post={post} />
                    </div>
                  );
                })}
              </div>
            );

          if (data) {
            const { posts } = data;
            return (
              <>
                <div className="row blog-posts-list">
                  {posts.edges.map((item, index) => {
                    const post = {
                      id: item.node.id,
                      title: item.node.title,
                      summary: item.node.summary,
                      shortDescription: item.node.summary,
                      img: item.node.listingImageThumbSmall,
                      instanceTag: item.node.instances.edges[0].node.name,
                      layoutType: getLayoutType(index),
                      link: getTranslatedLink(BLOG_DETAILS, {
                        categorySlug,
                        postSlug: item.node.slug,
                      }),
                    };

                    return (
                      <div
                        className="col-xs-6 col-sm-6 col-md-6 col-lg-4 mb-3"
                        // className={className({
                        //   "mb-3": true,
                        //   "col-xs-12 col-sm-12 col-md-12 col-lg-6":
                        //     post.layoutType === BACKGROUND_IMAGE_CARD_TYPE ||
                        //     post.layoutType === FEATURED_CARD_TYPE,
                        //   "col-xs-6 col-sm-6 col-md-6 col-lg-3":
                        //     post.layoutType === COMMON_CARD_TYPE,
                        // })}
                        key={index}
                      >
                        <BlogPostCard post={post} />
                      </div>
                    );
                  })}
                </div>
                {showPaginator && (
                  <Paginator
                    elementId={"postsListElement"}
                    pageSize={pageSize}
                    pageInfo={posts.pageInfo}
                    totalCount={posts.totalCount}
                  />
                )}
              </>
            );
          }

          return <LoadingMessage />;
        }}
      </Query>
    </Element>
  );
}

PostsList.propTypes = {
  categorySlug: PropTypes.string,
  showPaginator: PropTypes.bool,
};

import PropTypes from "prop-types";
import wave from "./img/wave-form.svg";
import "./styles/hero.scss";
import classNames from "classnames";
import { isMARINE } from "services/instances";

export default function Hero({
  titleClassName = "",
  title = "",
  description = "",
  backgroundImage,
}) {
  return (
    <div
      className="hero-blog"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <img className="wave" src={wave} alt="wave" />
      <div className="hero-title__content">
        <div
          className={classNames({
            container: true,
            "hero-blog--marine": isMARINE(),
          })}
        >
          <h1
            className={classNames({
              "hero-title-heading m-0": true,
              [titleClassName]: titleClassName,
            })}
          >
            {title}
          </h1>
          <p className="hero-description-heading m-0">{description}</p>
        </div>
      </div>
    </div>
  );
}

Hero.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  backgroundImage: PropTypes.string.isRequired,
  titleClassName: PropTypes.string.isRequired,
};

import PropTypes from "prop-types";
import "./styles/ProjectCard.scss";
import Skeleton from "react-loading-skeleton";
import TranslatedLink from "routes/components/TranslatedLink";
import { BLOG_DETAILS } from "routes";
import { H2_TAG, H4_TAG } from "utils/components/TitleUnderlined/constants";
import { FormattedMessage } from "react-intl";

const CategoryCard = ({ post = null }) => (
  <div className="project-card">
    <div className="category-card__image">
      {console.log("test post.categories.edges[0]", post?.categories?.edges)}
      {post && (
        <TranslatedLink
          className="d-block w-100"
          params={{
            categorySlug: post.categories.edges[0].node.slug,
            postSlug: post.slug,
          }}
          name={BLOG_DETAILS}
        >
          <img
            src={post.listingImageThumbSmall}
            alt={post.title}
            title={post.title}
            className="d-block w-100 h-100 img-fluid"
          />
        </TranslatedLink>
      )}
    </div>
    <div className="project-card__details p-3">
      <p className="tag-instance-content--dark">
        {!post?.categories ? (
          <Skeleton width="50px" />
        ) : (
          post.categories.edges.map(({ node: { name } }) => name)
        )}
      </p>
      <h2 className="blog-card-title">
        {post ? `${post.title}` : <Skeleton width={180} />}
      </h2>
      <p className="tag-instance-content--dark">
        {!post?.created ? (
          <Skeleton width="50px" />
        ) : (
          new Date(post.created).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        )}
      </p>
      <p className="blog-post-title__description-text">
        {post ? post.summary : <Skeleton width={80} />}
      </p>
      <TranslatedLink
        params={{
          categorySlug: post?.categories.edges[0].node.slug,
          postSlug: post?.slug,
        }}
        name={BLOG_DETAILS}
      >
        <button className="btn btn-dark cursor-pointer">
          <FormattedMessage id="Button.readMore" defaultMessage="Leer más" />
          <i className="fa fa-long-arrow-right ml-2" />
        </button>
      </TranslatedLink>
    </div>
  </div>
);

CategoryCard.propTypes = {
  titleHTag: PropTypes.oneOf([H2_TAG, H4_TAG]),
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    coverUrl: PropTypes.string.isRequired,
    altCover: PropTypes.string.isRequired,
  }),
};

export default CategoryCard;

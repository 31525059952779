import PropTypes from "prop-types";
import { scroller } from "react-scroll";
import {
  QUERY_PARAM_AFTER_CURSOR,
  QUERY_PARAM_BEFORE_CURSOR,
  QUERY_PARAM_FIRST,
  QUERY_PARAM_LAST,
} from "utils/components/Paginator/constants";
import QueryParams from "services/browser-history/QueryParams";

const usePaginator = ({
  pageSize,
  elementId,
  pageInfo,
  totalCount,
  history,
  location,
}) => {
  const queryParams = new QueryParams(location.search);
  const paginationInfo = resolvePaginationInfo();

  function scrollToBegin() {
    scroller.scrollTo(elementId, {
      duration: 750,
      smooth: true,
      offset: -120,
    });
  }

  /**
   * Resolve the pagination data based on totalCount and pageInfo
   */
  function resolvePaginationInfo() {
    if (totalCount > 0 && pageInfo.startCursor && pageInfo.endCursor) {
      const startCursor = resolveCursor(pageInfo.startCursor);
      const endCursor = resolveCursor(pageInfo.endCursor);
      const pageRange = {
        start: 0,
        end: pageSize - 1,
      };
      let currentPage = 1,
        pageFound = false;
      let pages = Math.floor(totalCount / pageSize);

      if (totalCount % pageSize !== 0) pages += 1;

      while (pageRange.start < totalCount && !pageFound) {
        if (pageRange.start <= startCursor && pageRange.end >= endCursor) {
          pageFound = true;
        } else {
          pageRange.start += pageSize;
          pageRange.end += pageSize;
          currentPage += 1;
        }
      }

      const newPaginationInfo = { pages, currentPage };
      return newPaginationInfo;
    }
  }

  function resolveCursor(cursor) {
    const decodedCursor = window.atob(cursor).split(":");
    if (decodedCursor.length === 2 && decodedCursor[0] === "arrayconnection")
      return Number(decodedCursor[1]);
    // TODO: Throws an error here
    else return 1;
  }

  /**
   * Handle the number page click, search for the "afterCursor" corresponding to the page received and update
   * the queryParams
   *
   * @param page (Number)
   */
  function goToPage(page) {
    const reference =
      Math.floor((totalCount / paginationInfo.pages) * page) - 1;
    const pageRange = {
      start: 0,
      end: pageSize - 1,
    };
    let pageStart = 0,
      pageFound = false;

    while (pageRange.start <= totalCount && !pageFound) {
      if (reference >= pageRange.start && reference <= pageRange.end) {
        pageFound = true;
        pageStart = pageRange.start;
      } else {
        pageRange.start += pageSize;
        pageRange.end += pageSize;
      }
    }

    queryParams.update({
      [QUERY_PARAM_BEFORE_CURSOR]: null,
      [QUERY_PARAM_LAST]: null,
      [QUERY_PARAM_AFTER_CURSOR]: convertCursor(pageStart - 1),
      [QUERY_PARAM_FIRST]: pageSize,
    });

    history.push({
      pathname: location.pathname,
      search: queryParams.asSearchString,
    });

    scrollToBegin();
  }

  /**
   * Receive a number and convert to relay cursor
   *
   * @param number (Number)
   * @return {string}
   */
  function convertCursor(number) {
    return window.btoa(`arrayconnection:${number}`);
  }

  function handlePageClick({ selected }) {
    goToPage(selected + 1);
  }

  return { paginationInfo, handlePageClick };
};

usePaginator.propTypes = {
  pageSize: PropTypes.number.isRequired,
  elementId: PropTypes.string.isRequired,
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
    startCursor: PropTypes.string,
    endCursor: PropTypes.string,
  }).isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default usePaginator;
